import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["picker"];

  pickerTargetConnected(element) {
    flatpickr(element, {
      monthSelectorType: "static",
      locale: {
        weekdays: {
          shorthand: ["S", "M", "T", "W", "T", "F", "S"],
          longhand: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
        },
      },
    });
  }
}
