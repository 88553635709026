import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  connect() {
    const scrollPos = localStorage.getItem(`${this.listTarget.id}_scrollpos`);
    if (scrollPos) this.listTarget.scrollTop = scrollPos;
  }

  scroll() {
    localStorage.setItem(
      `${this.listTarget.id}_scrollpos`,
      this.listTarget.scrollTop
    );
  }
}
