import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  handleChange() {
    this.toggleContainerVisibility();
  }

  toggleContainerVisibility() {
    if (this.containerTarget.classList.contains("hidden")) {
      this.containerTarget.classList.remove("hidden");
    }
  }
}
