// This file is deprecated and will be removed in the future.
// I've left it for legacy reasons.
// https://github.com/heyvito/lucide-rails

import {
  AlarmClock,
  AlarmMinus,
  AlertCircle,
  ArrowLeft,
  ArrowRight,
  Banknote,
  BarChart3,
  Blocks,
  Briefcase,
  Building,
  Calendar,
  CalendarDays,
  Check,
  CheckCircle,
  CheckCircle2,
  ChevronDown,
  ChevronRight,
  ClipboardCheck,
  ClipboardList,
  Download,
  Eye,
  EyeOff,
  File,
  FileStack,
  FileText,
  FileUp,
  FlaskConical,
  HelpCircle,
  History,
  Home,
  LineChart,
  LogIn,
  MapPin,
  Pencil,
  Plus,
  PoundSterling,
  RefreshCcw,
  Repeat,
  RotateCcw,
  Route,
  Scale,
  Search,
  Send,
  Settings,
  ShieldAlert,
  SlidersHorizontal,
  Star,
  StretchHorizontal,
  Trash2,
  Upload,
  UploadCloud,
  User,
  Users,
  Wallet,
  X,
  XCircle,
  createIcons,
} from "lucide";

const initializeIcons = () => {
  createIcons({
    icons: {
      AlarmClock,
      AlarmMinus,
      AlertCircle,
      ArrowLeft,
      ArrowRight,
      Banknote,
      BarChart3,
      Blocks,
      Briefcase,
      Building,
      Calendar,
      CalendarDays,
      Check,
      CheckCircle,
      CheckCircle2,
      ChevronDown,
      ChevronRight,
      ClipboardCheck,
      ClipboardList,
      Download,
      Eye,
      EyeOff,
      File,
      FileStack,
      FileText,
      FileUp,
      FlaskConical,
      HelpCircle,
      History,
      Home,
      LineChart,
      LogIn,
      MapPin,
      Pencil,
      Plus,
      PoundSterling,
      RefreshCcw,
      Repeat,
      RotateCcw,
      Route,
      Scale,
      Search,
      Send,
      Settings,
      ShieldAlert,
      SlidersHorizontal,
      Star,
      StretchHorizontal,
      Trash2,
      Upload,
      UploadCloud,
      User,
      Users,
      Wallet,
      X,
      XCircle,
    },
  });
};

document.addEventListener("turbo:load", initializeIcons);
document.addEventListener("turbo:frame-render", initializeIcons);
document.addEventListener("turbo:render", initializeIcons);

export { initializeIcons };
