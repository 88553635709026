import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.handleKeydown.bind(this));
  }

  handleKeydown(event) {
    if (event.key === "Enter" && event.target.tagName.toUpperCase() != 'TEXTAREA') {
      event.preventDefault();
    }
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.handleKeydown.bind(this));
  }
}
