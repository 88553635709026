import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bottom", "content", "modal", "submit", "title", "counter"];

  static values = {
    initialChangedFields: { type: Array, default: [] },
    editing: { type: Boolean, default: false },
    counter: { type: String },
  };

  getForm = () => this.contentTarget.querySelector("form");

  connect() {
    const form = this.getForm();
    form.addEventListener("input", this.processInput);
    form.addEventListener("change", this.processInput);
    this.counterTarget.addEventListener(
      "DOMSubtreeModified",
      this.counterToggle
    );
  }

  processInput = ({ target }) => {
    if (target.getAttribute("readonly") === "readonly") return;
    if (this.counterValue === "0") {
      this.editingValue = false;
    } else if (!this.editingValue) {
      this.editingValue = true;
    }
  };

  showEditingElements = () => {
    this.contentTarget.classList.add("editing-mode__content--editing");
    this.bottomTarget.classList.add("editing-mode__bottom--visible");
  };

  hideEditingElements = () => {
    this.contentTarget.classList.remove("editing-mode__content--editing");
    this.bottomTarget.classList.remove("editing-mode__bottom--visible");
  };

  closeActionMode = () => {
    this.editingValue = false;
  };

  editingValueChanged(editing) {
    if (editing) {
      this.showEditingElements();
    } else {
      this.hideEditingElements();
    }
  }

  openModal = (event) => {
    const buttonData = event.target.dataset;
    this.titleTarget.innerHTML = buttonData.modalTitle;
    this.submitTarget.innerHTML = buttonData.modalButtonText;
    this.submitTarget.formAction = buttonData.formAction;
    this.submitTarget.formMethod = buttonData.method;
    this.modalTarget.classList.add("bulk-action-mode__modal--visible");
  };

  closeModal = () => {
    this.modalTarget.classList.remove("bulk-action-mode__modal--visible");
  };

  counterToggle = (e) => {
    this.counterValue = e.target?.innerHTML;
  };
}
