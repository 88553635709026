import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const year = new Date().getFullYear();
    this.inputTarget.setAttribute("min", year + "-01-01");
    this.inputTarget.setAttribute("max", year + "-12-31");
  }
}
