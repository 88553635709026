import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parent", "child", "selected", "allItems"];

  static values = {
    resourceName: String,
  };

  connect() {
    this.resourceName = this.parentTarget.dataset.resource || "communication";
    document.forms
      .namedItem(this.parentTarget.dataset.form)
      .addEventListener("submit", (e) =>
        this.onFormSubmit(e, this.resourceName)
      );
    if (this.getCheckedAllFromStorage()) {
      this.parentTarget.checked = true;
      this.toggleChildren();
    } else {
      this.parentTarget.checked = false;
      this.childTargets.map(
        (x) => (x.checked = this.getCheckedIdsFromStorage().includes(x.value))
      );
    }
  }

  toggleChildren() {
    if (this.parentTarget.checked) {
      this.addCheckedAllToStorage();
      this.childTargets.map((x) => (x.checked = true));
      this.allItemsTarget.value.split(" ").map((x) => this.addIdToStorage(x));
    } else {
      this.removeCheckedAllFromStorage();
      this.childTargets.map((x) => (x.checked = false));
      this.removeAllIdsFromStorage();
    }
    this.updateCounter();
  }

  toggleParent(event) {
    this.parentTarget.checked = false;
    this.removeCheckedAllFromStorage();
    if (event.target.checked) {
      this.addIdToStorage(event.target.value);
    } else {
      this.removeIdFromStorage(event.target.value);
    }
    this.updateCounter();
  }

  updateCounter() {
    const counter = document.getElementById("bulk-action-selected-counter");

    if (this.parentTarget.checked) {
      counter.innerHTML = "All";
    } else {
      counter.innerHTML = this.getCheckedIdsFromStorage().length;
      this.selectedTarget.value = this.getCheckedIdsFromStorage();
    }
  }

  onFormSubmit(_, resourceName) {
    localStorage.removeItem(`${resourceName}_ids`);
    localStorage.removeItem(`all_${resourceName}_ids`);
    document.getElementById("bulk-action-selected-counter").innerHTML = "0";
  }

  getCheckedAllFromStorage() {
    return JSON.parse(
      localStorage.getItem(`all_${this.resourceName}_ids`) || "false"
    );
  }

  removeCheckedAllFromStorage() {
    localStorage.removeItem(`all_${this.resourceName}_ids`);
  }

  addCheckedAllToStorage() {
    localStorage.setItem(`all_${this.resourceName}_ids`, "true");
  }

  getCheckedIdsFromStorage() {
    return JSON.parse(localStorage.getItem(`${this.resourceName}_ids`) || "[]");
  }

  removeAllIdsFromStorage() {
    localStorage.removeItem(`${this.resourceName}_ids`);
  }

  addIdToStorage(value) {
    const communication_ids = this.getCheckedIdsFromStorage();
    if (!communication_ids.includes(value)) {
      communication_ids.push(value);
      localStorage.setItem(
        `${this.resourceName}_ids`,
        JSON.stringify(communication_ids)
      );
    }
  }

  removeIdFromStorage(value) {
    const communication_ids = this.getCheckedIdsFromStorage();
    if (communication_ids.includes(value)) {
      const index = communication_ids.indexOf(value);
      communication_ids.splice(index, 1);
      localStorage.setItem(
        `${this.resourceName}_ids`,
        JSON.stringify(communication_ids)
      );
    }
  }
}
