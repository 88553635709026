import "@hotwired/turbo-rails";
import "./beta/controllers";
import "./beta/lucide_icons";
import "trix";
import "@rails/actiontext";
import "./utils/turbo_frame_helper";

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

Turbo.StreamActions.classname = function () {
  this.targetElements.forEach((target) => {
    if (!target.classList.contains(this.templateContent.textContent.trim())) {
      target.classList.add(this.templateContent.textContent);
    }
  });
};
