import flatpickr from "flatpickr";
import moment from "moment";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["recurring-dates"];
  static targets = ["picker"];
  static values = {
    dates: { type: Array, default: [] },
    persisted: { type: Boolean, default: false },
  };

  connect() {
    this.render();
  }

  render() {
    const taskDates = this.datesValue.map((date) => moment(date));
    const newTask = !this.persistedValue
    const isRecurring = this.recurringDatesOutlet.recurringTarget.checked;
    this.recurringDatesOutlet.dueDateValue = this.datesValue[0];
    const mode = (isRecurring && newTask) ? "range" : "single";

    let calendar = flatpickr(this.pickerTarget, {
      inline: true,
      mode: mode,
      onDayCreate: function (dObj, dStr, fp, dayElem) {
        if (taskDates.some((date) => date.isSame(dayElem.dateObj, "day"))) {
          dayElem.classList.add("task");
        }
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (mode === "range") {
          this.recurringDatesOutlet.setRange(selectedDates);
        } else {
          this.recurringDatesOutlet.setSingleDate(dateStr);
        }
      },
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["S", "M", "T", "W", "T", "F", "S"],
          longhand: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
        },
      },
    });

    if (this.datesValue.length > 0) {
      calendar.jumpToDate(this.datesValue[0]);
    }
  }
}
