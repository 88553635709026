import { Application } from "@hotwired/stimulus";
import DatePickerController from "./date_picker_controller";
import DocumentsController from "./documents_controller";
import ModalController from "./modal_controller";
import SearchableDropdownController from "./searchable_dropdown_controller";
import ToggleController from "./toggle_controller";
import EditingModeController from "./editing_mode_controller";
import ToggleFormElementsController from "./toggle_form_elements_controller";
import RiskDescriptorController from "./risk_descriptor_controller";
import SimpleDrawerController from "./simple_drawer_controller";
import RecurringDatesController from "./recurring_dates_controller";
import CalendarController from "./calendar_controller";
import IdenticonController from "./identicon_controller";
import FormSuggestionsController from "./form_suggestions_controller";
import DynamicFormController from "./dynamic_form_controller";
import CheckboxTrackerController from "./checkbox_tracker_controller";
import SimpleModalController from "./simple_modal_controller";
import IconController from "./icon_controller";
import CheckboxDisableOthersOnSelectAll from "./fee_split_controller";
import BasicTextEditorController from "./basic_text_editor_controller";
import CheckBoxSelectController from "./check_box_select_controller";
import WorkflowDocumentController from "./workflow_document_controller";
import AccordionController from "./accordion_controller";
import WorkflowStepController from "./workflow_step_controller";
import TabsController from "./tabs_controller";
import NestedFieldsController from "./nested_fields_controller";
import AddRowController from "./add_row_controller";
import SingleSelectController from "./single_select_controller";
import DisableSubmitController from "./disable_submit_controller";
import FormSubmitController from "./form_submit_controller";
import LinkToController from "./link_to_controller";
import NotificationController from "./notification_controller";
import ActiveToggleController from "./active_toggle_controller";
import BulkActionModeController from "./bulk_action_mode_controller";
import CheckboxSelectAllController from "./checkbox_select_all_controller";
import CloseModalAndSubmitController from "./close_modal_and_submit_controller";
import DateAgeController from "./date_age_controller";
import TagboxController from "./tagbox_controller";
import VisibilityController from "./visibility_controller";
import InterceptKeydownController from "./intercept_keydown_controller";
import ExpandingListController from "./expanding_list_controller";
import ProfileAvatarController from "./profile_avatar_controller";
import ScrollRetainController from "./scroll_retain_controller";
import ChangeToShowController from "./change_to_show_controller";
import ToggleAllBoxesController from "./toggle_all_boxes_controller";
import DayMonthInputController from "./day_month_input_controller";
import SubmitAndRedirectController from "./submit_and_redirect_controller";
import FocusController from "./focus_controller";
import SearchResultsController from "./search_results_controller";
import SearchWithinFormController from "./search_within_form_controller";
import SimpleCalendarController from "./simple_calendar_controller";

const application = Application.start();

application.register("date-picker", DatePickerController);
application.register("documents", DocumentsController);
application.register("modal", ModalController);
application.register("editing-mode", EditingModeController);
application.register("searchable-dropdown", SearchableDropdownController);
application.register("toggle", ToggleController);
application.register("toggle-form-elements", ToggleFormElementsController);
application.register("risk-descriptor", RiskDescriptorController);
application.register("simple-drawer", SimpleDrawerController);
application.register("recurring-dates", RecurringDatesController);
application.register("calendar", CalendarController);
application.register("identicon", IdenticonController);
application.register("form-suggestions", FormSuggestionsController);
application.register("dynamic-form", DynamicFormController);
application.register("checkbox-tracker", CheckboxTrackerController);
application.register("simple-modal", SimpleModalController);
application.register("icon", IconController);
application.register("fee-split", CheckboxDisableOthersOnSelectAll);
application.register("basic-text-editor", BasicTextEditorController);
application.register("check-box-select", CheckBoxSelectController);
application.register("workflow-document", WorkflowDocumentController);
application.register("accordion", AccordionController);
application.register("workflow-step", WorkflowStepController);
application.register("tabs", TabsController);
application.register("nested-fields", NestedFieldsController);
application.register("add-row", AddRowController);
application.register("single-select", SingleSelectController);
application.register("disable-submit", DisableSubmitController);
application.register("form-submit", FormSubmitController);
application.register("link-to", LinkToController);
application.register("notification", NotificationController);
application.register("active-toggle", ActiveToggleController);
application.register("bulk-action-mode", BulkActionModeController);
application.register("checkbox-select-all", CheckboxSelectAllController);
application.register("close-modal-and-submit", CloseModalAndSubmitController);
application.register("date-age", DateAgeController);
application.register("tagbox", TagboxController);
application.register("visibility", VisibilityController);
application.register("intercept-keydown", InterceptKeydownController);
application.register("expanding-list", ExpandingListController);
application.register("profile-avatar", ProfileAvatarController);
application.register("scroll-retain", ScrollRetainController);
application.register("change-to-show", ChangeToShowController);
application.register("toggle-all-boxes", ToggleAllBoxesController);
application.register("day-month-input", DayMonthInputController);
application.register("submit-and-redirect", SubmitAndRedirectController);
application.register("focus", FocusController);
application.register("search-results", SearchResultsController);
application.register("search-within-form", SearchWithinFormController);
application.register("simple-calendar", SimpleCalendarController);
