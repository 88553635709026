import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["documents", "document", "input"];

  connect() {
    document.addEventListener("trix-file-accept", function (event) {
      event.preventDefault();
    });

    if (this.inputTarget.dataset.disabled === "true") {
      document.querySelector('trix-editor').editor.element.setAttribute('contentEditable', false);
    }
  }
}
