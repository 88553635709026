import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  hideElement(event) {
    event.preventDefault();
    this.elementTarget.classList.add("visually-hidden");
  }

  showForm(event) {
    event.preventDefault();
    this.elementTarget.classList.remove("visually-hidden");
  }
}
