import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    method: { type: String, default: "" },
  };

  saveForm(event) {
    const form = this.formTarget;
    if (form) {
      form.appendChild(this._addInput("skip_and_redirect", "true"));
      if (event.target.dataset.target)
        form.appendChild(this._addInput("target", event.target.dataset.target));
      if (event.target.dataset.planVersion)
        form.appendChild(
          this._addInput("plan_version", event.target.dataset.planVersion)
        );
      if (event.target.dataset.focusField)
        form.appendChild(
          this._addInput("focus_field", event.target.dataset.focusField)
        );
      if (event.target.dataset.resourceId)
        form.appendChild(
          this._addInput("resource_id", event.target.dataset.resourceId)
        );
      if (event.target.dataset.resourceType)
        form.appendChild(
          this._addInput("resource_type", event.target.dataset.resourceType)
        );
      form.requestSubmit();
    }
  }

  _getForm() {
    if (this.hasFormTarget) {
      return this.formTarget;
    } else {
      return this.saveButtonTarget.closest("form");
    }
  }

  _addInput(name, value) {
    const input = document.createElement("input");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    input.setAttribute("type", "hidden");
    return input;
  }
}
