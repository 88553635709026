import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["all", "type"];

  connect() {
    this.updateCheckboxes();
  }

  updateCheckboxes() {
    if (this.allTarget.checked) {
      this.typeTargets.forEach((checkbox) => {
        checkbox.checked = true;
        checkbox.disabled = true;
      });
    } else {
      this.typeTargets.forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  }

  toggleAll() {
    this.updateCheckboxes();
  }
}
