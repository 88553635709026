import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { focusField: String };

  connect() {
    this.focusOnField();
  }

  focusOnField() {
    const hash = window.location.hash.substring(1);
    const focusField = this.focusFieldValue || hash;

    if (focusField) {
      const element = document.getElementById(focusField);
      if (element) {
        element.focus();
      }
    }
  }
}
