import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expandable", "item", "link"];

  connect() {
    this.linkTarget.addEventListener("click", (event) =>
      this.handleLinkClick(event)
    );
  }

  handleLinkClick(event) {
    event.preventDefault();
    this.toggle();
  }

  toggleText() {
    if (this.linkTarget.innerText === "See all ->") {
      this.linkTarget.innerText = "<- Collapse";
    } else {
      this.linkTarget.innerText = "See all ->";
    }
  }

  toggle() {
    this.toggleText();
    this.expandableTargets.forEach((expandable) => {
      if (expandable.classList.contains("expanded")) {
        this.collapse(expandable);
      } else {
        this.expand(expandable);
      }
    });
  }

  expand(expandableElement) {
    expandableElement.classList.add("expanded");
  }

  collapse(expandableElement) {
    expandableElement.classList.remove("expanded");
  }
}
