import _ from "lodash";
import { Controller } from "@hotwired/stimulus";
import Qs from "qs";

export default class extends Controller {
  static targets = ["refresh"];

  static values = {
    url: String,
    formRef: String,
  };

  setSrcValue(params) {
    const path =
      this.urlValue +
      "?" +
      Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    this.element.src = path;
  }

  update(e) {
    e.preventDefault();
    const { params } = e.target.dataset;
    this.setSrcValue(JSON.parse(params || ""));
  }

  updateWithPath(e) {
    e.preventDefault();
    const { value } = e.target;
    const { params, path } = e.target.dataset;
    this.setSrcValue(
      _.setWith(JSON.parse(params || "{}"), path, value, Object)
    );
  }

  updateForm(e) {
    e.preventDefault();
    e.target.blur();
    this.refreshTarget.click();
  }

  submitForm(event) {
    event.preventDefault();
    this.element.closest("form").requestSubmit();
  }

  submitAndRefresh(event) {
    event.preventDefault();
    const form = this.element.closest("form");
    const formData = new FormData(form);

    const csrfTokenMeta = document.querySelector("meta[name='csrf-token']");
    const headers = {};

    if (csrfTokenMeta) {
      headers["X-CSRF-Token"] = csrfTokenMeta.content;
    }

    fetch(form.action, {
      method: "POST",
      body: formData,
      headers: headers,
      credentials: "same-origin",
    }).then(() => {
      this.refreshTarget.click();
    });
  }
}
