import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "tags",
    "tag",
    "items",
    "item",
    "addNew",
    "addNewContainer",
  ];
  static values = {
    inputName: String,
    inputId: String,
    items: Array,
  };

  connect() {
    this.inputTarget.value = "";
    this.toggleTagsVisibility();
    document.addEventListener("click", this.clickedOutside.bind(this));
    this.filterItems();
  }

  clickedOutside(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  addWithEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (this.allItemTargetsAreVisuallyHidden()) {
        this.addNewTag(event);
        this.inputTarget.blur();
      }
    }
  }

  addTag(event) {
    event.preventDefault();

    this.tagsTarget.insertAdjacentHTML(
      "beforebegin",
      `<span data-action="click->tagbox#removeTag" class="tagbox__tag" data-tagbox-target="tag">
        <span class="tagbox__remove">x</span>
        <input
          multiple="multiple"
          value="${event.target.innerText}"
          autocomplete="off"
          type="hidden"
          name="${this.inputNameValue}"
          id="${this.inputIdValue}"
        >
        ${event.target.innerText}
      </span>`
    );
    this.inputTarget.value = "";
    this.toggleTagsVisibility();
    this.close();
    this.filterItems();
  }

  addNewTag(event) {
    event.preventDefault();
    const inputValue = this.inputTarget.value.trim();
    if (inputValue === "") return;

    this.addTagFromContent(inputValue);

    this.inputTarget.value = "";
    let livePreview = this.addNewContainerTarget.querySelector(
      ".tagbox__tag--preview"
    );
    if (livePreview) livePreview.remove();
    this.toggleTagsVisibility();
    this.close();
    this.filterItems();
  }

  updateLiveTag() {
    const inputValue = this.inputTarget.value.trim();
    let livePreview = this.addNewContainerTarget.querySelector(
      ".tagbox__tag--preview"
    );

    if (inputValue.length > 0) {
      if (!livePreview) {
        this.addNewContainerTarget.insertAdjacentHTML(
          "beforeend",
          `<span class="tagbox__tag tagbox__tag--preview">
            ${inputValue}
          </span>`
        );
      } else {
        livePreview.textContent = inputValue;
      }
    }
  }

  confirmPreviewTag(event) {
    const tagContent = event.currentTarget.textContent.trim();
    if (tagContent) {
      this.addTagFromContent(tagContent);
      event.currentTarget.remove();
    }
  }

  addTagFromContent(content) {
    this.tagsTarget.insertAdjacentHTML(
      "beforebegin",
      `<span data-action="click->tagbox#removeTag" class="tagbox__tag" data-tagbox-target="tag">
        <span class="tagbox__remove">x</span>
        <input
          multiple="multiple"
          value="${content}"
          autocomplete="off"
          type="hidden"
          name="${this.inputNameValue}"
          id="${this.inputIdValue}"
        >
        ${content}
      </span>`
    );
    this.inputTarget.value = "";
  }

  hideLiveTag() {
    this.addNewTarget.classList.add("visually-hidden");
  }

  removeTag(event) {
    event.currentTarget.remove();
    this.toggleTagsVisibility();
    this.filterItems();
  }

  toggleTagsVisibility() {
    if (this.tagTargets.length > 0) {
      this.tagsTarget.classList.remove("visually-hidden");
    } else {
      this.tagsTarget.classList.add("visually-hidden");
    }
  }

  toggleAddNew() {
    if (this.inputTarget.value === "") {
      this.addNewTarget.classList.add("visually-hidden");
    } else {
      this.addNewTarget.classList.remove("visually-hidden");
    }
  }

  close() {
    this.itemsTarget.classList.add("visually-hidden");
    this.isOpen = false;
  }

  open() {
    this.itemsTarget.classList.remove("visually-hidden");
    this.isOpen = true;
  }

  toggle(event) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  filterItems() {
    const filter = this.inputTarget.value.toLowerCase();
    const currentlySelected = this.tagTargets.map((tag) => {
      const tagInput = tag.querySelector("input");
      return tagInput.value.toLowerCase();
    });

    this.itemTargets.forEach((item) => {
      if (currentlySelected.includes(item.innerText.toLowerCase())) {
        item.classList.add("visually-hidden");
      } else if (
        filter === "" ||
        item.innerText.toLowerCase().includes(filter)
      ) {
        item.classList.remove("visually-hidden");
      } else {
        item.classList.add("visually-hidden");
      }
    });
  }

  allItemTargetsAreVisuallyHidden() {
    return this.itemTargets.every((input) => {
      return input.classList.contains("visually-hidden");
    });
  }
}
