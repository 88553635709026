import { Controller } from "@hotwired/stimulus";
import { initializeIcons } from "../lucide_icons";

export default class extends Controller {
  connect() {
    initializeIcons();
  }

  destroy() {
    this.element.remove();
  }

  destroyWithTurbo() {
    // Use this when the action you're calling also renders to a turbo frame.
    setTimeout(this.destroy.bind(this), 0);
  }
}
