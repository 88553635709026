import moment from "moment";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["date", "age"];

  static values = {
    dob: String,
  };
  
  updateDate() {
    if (this.ageTarget.value && this.dobValue) {
      this.dateTarget.value = this.calculateDate(this.ageTarget.value);
    }
  }

  updateAge() {
    if (this.dateTarget.value && this.dobValue) {
      this.ageTarget.value = this.calculateAge(this.dateTarget.value);
    }
  }

  calculateAge(date) {
    const dob = moment(this.dobValue);
    const now = moment(date);
    return now.diff(dob, "years");
  }

  calculateDate(age) {
    const dob = moment(this.dobValue);
    return dob.add(age, "years").format("YYYY-MM-DD");
  }

  calculateDob() {
    const dob = moment(this.dateTarget.value);
    const now = moment();
    this.ageTarget.value = now.diff(dob, "years");
  }
}
