import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    event.preventDefault()
    const isEditingMode = document.getElementsByClassName("editing-mode__modal--visible");
    if (isEditingMode.length === 0) {
      this.modalTarget.classList.add("modal--open");
    }
  }

  closePrevent(event) {
    event.preventDefault()
    this.modalTarget.classList.remove("modal--open");
  }

  close() {
    this.modalTarget.classList.remove("modal--open");
  }
}
