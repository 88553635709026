import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "element",
    "mirrorElement",
    "falseElement",
    "falseInput",
    "input",
  ];

  static values = {
    initial: String,
    trigger: String,
    falseTrigger: String,
    triggers: { type: Array, default: [] },
    checkboxCount: { type: Number, default: 0 },
  };

  connect() {
    this.toggleTarget({
      target: this.inputTarget,
      useInitial: !!this.initialValue,
    });
  }

  showElement() {
    this.elementTargets.forEach((element) => {
      element.style.display = "block";
    });
    this.mirrorElementTargets.forEach((element) => {
      element.style.display = "none";
    });
  }

  showFalseElement() {
    this.falseElementTargets.forEach((element) => {
      element.style.display = "block";
    });
  }

  hideFalseElement() {
    this.falseElementTargets.forEach((element) => {
      element.style.display = "none";
    });
  }

  hideElement() {
    this.elementTargets.forEach((element) => {
      element.style.display = "none";
    });

    this.mirrorElementTargets.forEach((element) => {
      element.style.display = "block";
    });
  }

  checkboxEvaluator(target) {
    if (this.triggerValue) {
      target.checked === !this.triggerValue
        ? this.showElement()
        : this.hideElement();
    } else {
      if (target.checked) {
        this.checkboxCountValue += 1;
      } else {
        this.checkboxCountValue += -1;
      }
      this.checkboxCountValue > -1 ? this.showElement() : this.hideElement();
    }
  }

  radioEvaluator(target, useInitial = false) {
    this.toggleMainTarget(target, useInitial);

    if (this.falseTargetsPresent()) {
      this.toggleFalseTarget(useInitial);
    }
  }

  toggleMainTarget(target, useInitial) {
    if (
      (useInitial && this.triggerValue === this.initialValue) ||
      (!useInitial && this.triggerValue === target.value && target.checked) ||
      this.triggersValue.includes(target.value)
    ) {
      this.showElement();
    } else {
      this.hideElement();
    }
  }

  toggleFalseTarget(useInitial) {
    if (
      (useInitial && this.falseTriggerValue === this.initialValue) ||
      (!useInitial &&
        this.falseTriggerValue === this.falseInputTarget.value &&
        this.falseInputTarget.checked)
    ) {
      this.showFalseElement();
    } else {
      this.hideFalseElement();
    }
  }

  selectEvaluator(target) {
    if (
      (this.triggerValue === target.value && this.triggersValue.length === 0) ||
      this.triggersValue.includes(target.value)
    ) {
      this.showElement();
    } else {
      this.hideElement();
    }
  }

  toggleTarget({ target, useInitial }) {
    switch (target.type) {
      case "checkbox":
        this.checkboxEvaluator(target);
        break;
      case "radio":
        this.radioEvaluator(target, useInitial);
        break;
      case "select-one":
        this.selectEvaluator(target);
        break;
      default:
        break;
    }
  }

  falseTargetsPresent() {
    return (
      this.falseElementTargets.length > 0 && this.falseInputTargets.length > 0
    );
  }
}
