import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "hiddenElement"];

  connect() {
    this.countBoxes();
  }

  countBoxes() {
    let count = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;
    let displayValue = "none";

    if (count > 1) {
      displayValue = "block";
    } else {
      displayValue = "none";
    }

    this.hiddenElementTarget.style.display = displayValue;
  }
}
