import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  static values = { index: 0 };

  connect() {
    this.restoreIndexFromLocalStorage();
    this.makeTabActive();
    this.makeContentActive();
    document.addEventListener(
      "turbo:before-visit",
      this.handleBeforeVisit.bind(this)
    );
    this.element.addEventListener(
      "turbo:frame-load",
      this.handleTurboFrameLoad.bind(this)
    );
    this.previousUrl = window.location.href;
  }

  disconnect() {
    document.removeEventListener(
      "turbo:before-visit",
      this.handleBeforeVisit.bind(this)
    );
    this.element.removeEventListener(
      "turbo:frame-load",
      this.handleTurboFrameLoad.bind(this)
    );
    if (this.previousUrl !== window.location.href) {
      this.clearIndexFromLocalStorage();
    }
  }

  handleTurboFrameLoad() {
    this.restoreIndexFromLocalStorage();
    this.makeTabActive();
    this.makeContentActive();
  }

  handleBeforeVisit() {
    this.clearIndexFromLocalStorage();
  }

  switchTab(event) {
    event.preventDefault();

    this.indexValue = this.tabTargets.indexOf(event.currentTarget);

    this.makeTabActive();
    this.makeContentActive();
    this.saveCurrentIndexToLocalStorage();
  }

  makeTabActive() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("tab--active");
    });

    this.tabTargets[this.indexValue].classList.add("tab--active");
  }

  makeContentActive() {
    this.contentTargets.forEach((content) => {
      content.classList.add("visually-hidden");
    });

    this.contentTargets[this.indexValue].classList.remove("visually-hidden");
  }

  saveCurrentIndexToLocalStorage() {
    const key = this.localStorageKey();
    localStorage.setItem(key, this.indexValue);
  }

  restoreIndexFromLocalStorage() {
    const key = this.localStorageKey();
    const storedIndex = localStorage.getItem(key);

    if (storedIndex !== null) {
      this.indexValue = parseInt(storedIndex, 10);
    }
  }

  clearIndexFromLocalStorage() {
    const key = this.localStorageKey();
    localStorage.removeItem(key);
  }

  localStorageKey() {
    return `tabs-${this.element.id}`;
  }
}
