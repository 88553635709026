import moment from "moment";
import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static outlets = ["calendar"];
  static targets = [
    "startDate",
    "endDate",
    "frequency",
    "recurring",
    "setButton",
    "calendar",
    "dueDateField",
    "recurringIcon",
    "recurringText",
    "recurringExterior",
    "recurringButtonText",
    "recurringButtonContainer",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
    "sun",
  ];

  static values = {
    valid: Boolean,
    dueDate: String,
  };

  connect() {
    this.validValue = this._canSetDates();
    this._setRecurringText();
  }

  open(event) {
    event.preventDefault();
    this.calendarTarget.classList.remove("visually-hidden");
  }

  close(event) {
    if (event && !this.element.contains(event.target)) {
      this._hide();
      this.toggleRecurringVisibility();
    }
  }

  toggleOpenOrClose(event) {
    if (this.calendarTarget.classList.contains("visually-hidden")) {
      this.open(event);
      this.toggleRecurringVisibility();
    } else {
      this.close();
    }
  }

  toggleRecurringVisibility() {
    if (this.recurringTarget.checked) {
      this.recurringButtonContainerTarget.classList.add("visibility-hidden");
    } else {
      this.recurringButtonContainerTarget.classList.remove("visibility-hidden");
    }
  }

  updateRecurringButtonText() {
    if (this.recurringTarget.checked) {
      this.recurringButtonTextTarget.textContent = "Cancel recurrence";
    } else {
      this.recurringButtonTextTarget.textContent = "Set to repeat";
    }
    this._setRecurringText();
  }

  setSingleDate(date) {
    this.dueDateFieldTarget.value = date;
    this._hide();
  }

  setRecurrence(event) {
    event.preventDefault();
    this.dueDateFieldTarget.value = this.dueDateValue;
    this._setRecurringText();
    this._hide();
  }

  validValueChanged() {
    if (this.validValue) {
      this.setButtonTarget.disabled = false;
    } else {
      this.setButtonTarget.disabled = true;
    }
  }

  synchronizeRecurringCheckboxes() {
    const isRecurringChecked = this.recurringTargets.some(
      (target) => target.checked
    );
    this.recurringTargets.forEach(
      (target) => (target.checked = isRecurringChecked)
    );
  }

  fetchDates() {
    this.validValue = this._canSetDates();
    if (this.validValue) {
      get("/beta/tasks/recurring_dates", {
        responseKind: "turbo-stream",
        query: {
          start_date: this.startDateTarget.value,
          end_date: this.endDateTarget.value,
          frequency: this.frequencyTarget.value,
          recurring: this.recurringTarget.checked,
          monday: this.monTarget.checked,
          tuesday: this.tueTarget.checked,
          wednesday: this.wedTarget.checked,
          thursday: this.thuTarget.checked,
          friday: this.friTarget.checked,
          saturday: this.satTarget.checked,
          sunday: this.sunTarget.checked,
        },
      });
    }
  }

  changeCalendarMode() {
    this.updateRecurringButtonText();
    this.toggleRecurringVisibility();
    this.calendarOutlet.render();
  }

  setRange(selectedDates) {
    if (selectedDates.length !== 2) {
      return;
    }

    this.startDateTarget.value = moment(selectedDates[0]).format("YYYY-MM-DD");
    this.endDateTarget.value = moment(selectedDates[1]).format("YYYY-MM-DD");
    this.fetchDates();
  }

  _hide() {
    this.calendarTarget.classList.add("visually-hidden");
    this.updateRecurringButtonText();
    if (this.recurringTarget.checked) {
      this.recurringIconTarget.classList.remove("visually-hidden");
    } else {
      this.recurringIconTarget.classList.add("visually-hidden");
    }
  }

  _canSetDates() {
    let requiredValues =
      this.startDateTarget.value &&
      this.endDateTarget.value &&
      this.frequencyTarget.value;

    if (!requiredValues) {
      return false;
    } else if (
      moment(this.startDateTarget.value).isAfter(
        moment(this.endDateTarget.value)
      )
    ) {
      return false;
    } else if (this.frequencyTarget.value === "weekly") {
      return Boolean(requiredValues && this._selectedDays().length > 0);
    } else {
      return true;
    }
  }

  _selectedDays() {
    return [
      this.monTarget,
      this.tueTarget,
      this.wedTarget,
      this.thuTarget,
      this.friTarget,
      this.satTarget,
      this.sunTarget,
    ].filter((day) => day.checked);
  }

  _recurringText() {
    if (this.frequencyTarget.value === "weekly") {
      return `Repeats weekly on ${this._recurringDays().join(", ")}`;
    } else {
      return `Repeats ${this.frequencyTarget.value}`;
    }
  }

  _setRecurringText() {
    if (this.recurringTarget.checked) {
      this.recurringTextTarget.textContent = this._recurringText();
    } else {
      this.recurringTextTarget.textContent = "";
    }
  }

  _recurringDays() {
    let days = [
      this.monTarget.checked ? "Monday" : "",
      this.tueTarget.checked ? "Tuesday" : "",
      this.wedTarget.checked ? "Wednesday" : "",
      this.thuTarget.checked ? "Thursday" : "",
      this.friTarget.checked ? "Friday" : "",
      this.satTarget.checked ? "Saturday" : "",
      this.sunTarget.checked ? "Sunday" : "",
    ].filter((day) => day !== "");

    return days;
  }
}
