import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  connect() {
    if (this.inputTargets.length > 0) this.submitTarget.disabled = true;
  }

  onchange() {
    if (this.submitTarget.dataset?.validate) {
      this.submitTarget.disabled = this.reportInputsValidity();
    } else {
      this.submitTarget.disabled = false;
    }
  }

  reportInputsValidity() {
    for (const input of this.inputTargets) {
      let visible = !input
        .closest("div.accordion")
        ?.classList?.contains("visually-hidden");

      if (visible && input.dataset?.required && !input.value) {
        return true;
      }
    }
    return false;
  }
}
