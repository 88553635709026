import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "submit", "radioGroup"];

  connect() {
    this.checkRadioButtons();
    this.checkRadioGroup();
  }

  checkRadioButtons() {
    const anyChecked = this.radioTargets.some((radio) => radio.checked);
    this.submitTarget.disabled = !anyChecked;
  }

  checkRadioGroup() {
    const anyChecked = this.radioGroupTargets.every((container) => {
      const radios = Array.from(container.getElementsByTagName("input"));
      return radios.some((radio) => radio.checked);
    });

    this.submitTarget.disabled = !anyChecked;
  }
}
