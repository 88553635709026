import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["saveButton", "form"];

  closeModalAndSave() {
    const form = this._getForm();
    if (form) {
      form.appendChild(this._addSkipValidationInput());
      form.requestSubmit();
    }
  }

  _getForm() {
    if (this.hasFormTarget) {
      return this.formTarget;
    } else {
      return this.saveButtonTarget.closest("form");
    }
  }

  _addSkipValidationInput() {
    const input = document.createElement("input");
    input.setAttribute("name", "skip_validation");
    input.setAttribute("value", "true");
    input.setAttribute("type", "hidden");
    return input;
  }
}
