import { Controller } from "@hotwired/stimulus";
import { initializeIcons } from "../lucide_icons";

export default class extends Controller {
  static targets = ["notification"];
  static values = {
    closeUrl: { type: String },
    autoHide: { type: String, default: "true" },
  };

  connect() {
    const notification = this.notificationTarget;
    initializeIcons();
    if (this.autoHideValue === "true") {
      setTimeout(function () {
        notification.classList.add("visually-hidden");
      }, 5000);
    }
  }

  close() {
    if (this.closeUrlValue) this.deleteSession();
    this.notificationTarget.classList.add("visually-hidden");
  }

  deleteSession() {
    fetch(this.closeUrlValue, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
    });
  }
}
