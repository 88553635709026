import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["suggestionsList"];

  static values = {
    url: String,
  };

  initialize() {
    this.timeout = null;
  }

  fetch({ currentTarget }) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.fetchSuggestions(currentTarget);
    }, 500);
  }

  fetchSuggestions(currentTarget) {
    get(this.urlValue, {
      responseKind: "turbo-stream",
      query: { query: currentTarget.value },
    });
  }

  clickOutside = (event) => {
    if (!this.element.contains(event.target)) {
      this.hide(event);
    }
  };

  show() {
    if (this.hasSuggestionsListTarget) {
      this.suggestionsListTarget.classList.remove(
        "form__suggestions-list--hidden"
      );
    }
  }

  hide(event) {
    if (event.relatedTarget instanceof HTMLAnchorElement) return;

    if (this.hasSuggestionsListTarget) {
      this.suggestionsListTarget.classList.add(
        "form__suggestions-list--hidden"
      );
    }
  }
}
