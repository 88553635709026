import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["descriptor", "selector"];
  static descriptions = [
    "",
    "You are prepared to take only a small amount of investment risk and it is important to you that your capital is protected. This means that your portfolio will concentrate on investments which provide low returns in the long term with low risk to your capital. Only a small amount of riskier assets will be included in your portfolio in order to increase the chance of obtaining better long term returns. A typical Cautious investor will be invested mostly in fixed interest and cash with a small element (up to about one third) in equities and property which can boost longer term returns but are associated with more risk. Because you are a Very Cautious investor there will be slightly more invested in fixed interest and cash. The range of assets provides diversification benefits which also help to reduce the overall risk.",
    "You are prepared to take only a small amount of investment risk and it is important to you that your capital is protected. This means that your portfolio will concentrate on investments which provide low returns in the long term with low risk to your capital. Only a small amount of riskier assets will be included in your portfolio in order to increase the chance of obtaining better long term returns. A typical Cautious investor will be invested mostly in fixed interest and cash with a small element (up to about one third) in equities and property which can boost longer term returns but are associated with more risk. The range of assets provides diversification benefits which also help to reduce the overall risk.",
    "You are prepared to take a limited investment risk in order to increase the chances of achieving a positive return but you only want to risk a small part of your capital to achieve this. A typical Cautious to Moderate portfolio will have up to half invested in fixed interest products which are low risk but have low returns. The larger part of the portfolio will be invested in equities and property which can boost longer term returns but are associated with more risk. Because you are a Low end of Cautious to Moderate investor there will be slightly more invested in fixed interest and cash. The range of assets provides diversification benefits which also help to reduce the overall risk.",
    "You are prepared to take a limited investment risk in order to increase the chances of achieving a positive return but you only want to risk a small part of your capital to achieve this. A typical Cautious to Moderate portfolio will have up to half invested in fixed interest products which are low risk but have low returns. The larger part of the portfolio will be invested in equities and property which can boost longer term returns but are associated with more risk. The range of assets provides diversification benefits which also help to reduce the overall risk.",
    "You are prepared to take a moderate amount of investment risk in order to increase the chance of achieving a positive return. Capital protection is less important to you than achieving a better return on the investment. A typical moderate investor will usually invest in a variety of assets to obtain diversification. There would be a substantially higher proportion of equities and property compared to fixed interest and cash.  Because you are a Low end of Moderate investor there will be slightly more invested in fixed interest and cash. The range of asset types helps reduce the overall risks as well as increasing the chance of better returns.",
    "You are prepared to take a moderate amount of investment risk in order to increase the chance of achieving a positive return. Capital protection is less important to you than achieving a better return on the investment. A typical moderate investor will usually invest in a variety of assets to obtain diversification. There would be a substantially higher proportion of equities and property compared to fixed interest and cash. The range of asset types helps reduce the overall risks as well as increasing the chance of better returns.",
    "You are prepared to take a medium degree of risk with your investment in return for the prospect of improving longer term investment performance. Short term capital protection is not important to you and you are willing to sacrifice some long term protection for the likelihood of greater returns. A typical Moderate to Adventurous investor will be invested mainly in equities but with other assets included to provide some diversification. There may be a small amount of specialised equity within the portfolio.  Because you are a Low end of Moderate to Adventurous investor there will be slightly more invested in fixed interest and cash.",
    "You are prepared to take a medium degree of risk with your investment in return for the prospect of improving longer term investment performance. Short term capital protection is not important to you and you are willing to sacrifice some long term protection for the likelihood of greater returns. A typical Moderate to Adventurous investor will be invested mainly in equities but with other assets included to provide some diversification. There may be a small amount of specialised equity within the portfolio.",
    "You are prepared to take a substantial degree of risk with your investment in return for the prospect of the highest possible longer term investment performance. You appreciate that over some periods of time there can be significant falls, as well as rises, in the value of your investment and you may get back less than you invest. This strategy holds significant risk in the shorter term. A typical Adventurous investor will be invested entirely in equities, both in the UK and overseas. There may be a significant proportion of the investment in specialised equities.",
    "You are prepared to take a substantial degree of risk with your investment in return for the prospect of the highest possible longer term investment performance. You appreciate that over some periods of time there can be significant falls, as well as rises, in the value of your investment and you may get back less than you invest. This strategy holds significant risk in the shorter term. A typical Adventurous investor will be invested entirely in equities, both in the UK and overseas. There may be a significant proportion of the investment in specialised equities. Because you are a Very Adventurous investor there will be slightly more invested in specialised equities.",
  ];

  updateRiskDescriptor() {
    const selectedRisk = this.selectorTarget.selectedIndex;
    const selectedDescription = this.constructor.descriptions[selectedRisk];
    const currentDescription = this.descriptorTarget.value;

    if (!this.constructor.descriptions.includes(currentDescription)) {
      if (
        window.confirm(
          "Your current risk description is not a default value. Do you want to overwrite it?"
        )
      ) {
        this.descriptorTarget.value = selectedDescription;
      }
    } else {
      this.descriptorTarget.value = selectedDescription;
    }
  }
}
