import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  onclick(event) {
    event.preventDefault();
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp(event.target.getAttribute('data-id'), 'g');
    this.listTarget.insertAdjacentHTML("beforeend", event.target.getAttribute('data-fields').replace(regexp, time));
    this.listTarget.scrollTop = this.listTarget.scrollHeight;
  }
}
