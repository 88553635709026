import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview", "errorModal", "avatar"];

  changeAvatar(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.avatarTarget.src = e.target.result;
    };

    reader.readAsDataURL(file);
    this.openPreviewModal();
    this.closeErrorModal(event);
  }

  openPreviewModal() {
    if (!this.previewTarget.classList.contains("profile__modal--visible")) {
      this.previewTarget.classList.add("profile__modal--visible");
    }
  }

  closePreviewModal(event) {
    event.preventDefault();
    if (this.previewTarget.classList.contains("profile__modal--visible")) {
      this.previewTarget.classList.remove("profile__modal--visible");
    }
  }

  closeAndSubmit() {
    if (this.previewTarget.classList.contains("profile__modal--visible")) {
      this.previewTarget.classList.remove("profile__modal--visible");
    }
  }

  closeErrorModal(event) {
    event.preventDefault();
    if (this.errorModalTarget.classList.contains("profile__modal--visible")) {
      this.errorModalTarget.classList.remove("profile__modal--visible");
    }
  }
}
