import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["primaryBox", "secondaryBox", "checkboxRefresh"];

  toggleAll(event) {
    if (this.primaryBoxTarget.checked) {
      this.secondaryBoxTargets.forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.refreshForm(event);
    }
  }

  uncheckPrimaryBox() {
    this.primaryBoxTarget.checked = false;
  }

  refreshForm(e) {
    e.preventDefault();
    this.checkboxRefreshTarget.click();
  }
}
