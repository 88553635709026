import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  static values = {
    activeClass: String,
  };

  removeAllActive() {
    this.containerTarget
      .querySelectorAll("." + this.activeClassValue)
      .forEach((el) => {
        el.classList.remove(this.activeClassValue);
      });
  }

  setActive(e) {
    this.removeAllActive();
    e.target.classList.add(this.activeClassValue);
  }
}
