import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  select(event) {
    event.preventDefault();

    this.inputTargets.forEach((input) => {
      if (input != event.target) {
        input.disabled = true;
      }
    });
  }

  reset(event) {
    event.preventDefault();

    this.inputTargets.forEach((input) => {
      input.checked = false;
      input.disabled = false;
    });
  }
}
