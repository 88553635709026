(() => {
  if (typeof CapybaraLockstep !== "undefined") {
    document.addEventListener("turbo:before-frame-render", (event) => {
      CapybaraLockstep?.startWork(`updating_${event.target.id}`);
    });
    document.addEventListener("turbo:frame-render", (event) => {
      CapybaraLockstep?.stopWork(`updating_${event.target.id}`);
    });
  }
})();
