import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["results", "delete"];

  connect() {
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown(event) {
    if (event.key === "Escape" || event.key === "Esc") {
      this.handleEscKey();
    }
  }

  handleEscKey() {
    this.deleteTarget.click();
  }

  seeMore(event) {
    const description = event.target.dataset.description;

    this.resultsTargets.forEach((result) => {
      result.classList.remove("search-bar__results--limited");

      if (result.dataset.description !== description) {
        result.classList.add("search-bar__results--hidden");
      } else {
        result.classList.add("search-bar__results--full");
      }
    });
  }

  back() {
    this.resultsTargets.forEach((result) => {
      result.classList.remove("search-bar__results--full");
      result.classList.remove("search-bar__results--hidden");
      result.classList.add("search-bar__results--limited");
    });
  }
}
