import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["input", "hiddenInput"];
  static values = { payload: Object, route: String };

  submit(event) {
    event.preventDefault();
    this.sendRequest(event.currentTarget.value);
  }

  select(event) {
    event.preventDefault();
    const gid = event.currentTarget.dataset.gid;
    const label = event.currentTarget.dataset.label;

    this.hiddenInputTarget.value = gid;
    this.inputTarget.value = label;
  }

  async sendRequest(query) {
    const csrfTokenMeta = document.querySelector("meta[name='csrf-token']");
    const formData = new FormData();

    for (const key in this.payloadValue) {
      if (Object.hasOwnProperty.call(this.payloadValue, key)) {
        formData.append(key, this.payloadValue[key]);
      }
    }

    formData.append("query", query);

    try {
      const response = await fetch(this.routeValue, {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": csrfTokenMeta?.content,
          Accept: "text/vnd.turbo-stream.html",
        },
        credentials: "same-origin",
      });

      if (response.ok) {
        const turboStream = await response.text();
        Turbo.renderStreamMessage(turboStream);
      } else {
        console.error("Fetch failed:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }
}
