import { Controller } from "@hotwired/stimulus";
import Identicon from "identicon.js";
import jsSHA from "jssha";

export default class extends Controller {
  static targets = ["image"];

  static values = {
    seed: String,
    size: { type: Number, default: 128 },
  };

  connect() {
    this.render();
  }

  identiconData() {
    const useableSeed = new jsSHA("SHA3-512", "TEXT")
      .update(this.seedValue)
      .getHash("HEX");

    return new Identicon(useableSeed, {
      size: this.sizeValue,
      background: [255, 255, 255, 0],
    }).toString();
  }

  render() {
    this.imageTarget.src = "data:image/png; base64," + this.identiconData();
  }
}
