import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { initializeIcons } from "../lucide_icons";

export default class extends Controller {
  static targets = ["documents", "document", "input"];

  connect() {
    this.documentArray = [];
  }

  documentTargetConnected() {
    initializeIcons();
  }

  submit({ target: { form } }) {
    form.submit();
  }

  onchange({ params }) {
    const { record_id, record_type } = params.payload;
    const fileBuffer = new DataTransfer();

    this.documentArray = this.documentArray.concat(
      Array.from(this.inputTarget.files)
    );
    this.documentArray.map((file) => fileBuffer.items.add(file));
    this.inputTarget.files = fileBuffer.files;

    const formData = new FormData();
    this.documentArray.forEach((file) => formData.append(`documents[]`, file));
    formData.append("target", this.documentsTarget.id);
    if (record_id) formData.append("record_id", record_id);
    formData.append("record_type", record_type);

    post(`/beta/documents`, {
      responseKind: "turbo-stream",
      body: formData,
    });
  }

  delete(event) {
    const { name, record_id, record_type, target_id } = event.params.payload;

    const fileBuffer = new DataTransfer();
    this.documentArray = this.documentArray.filter(
      (file) => file.name !== name
    );
    this.documentArray.map((file) => fileBuffer.items.add(file));
    this.inputTarget.files = fileBuffer.files;
    post(`/beta/documents/remove`, {
      responseKind: "turbo-stream",
      body: {
        target: target_id,
        record_id: record_id,
        record_type: record_type,
      },
    });
  }

  async attachDocumentToPlan({ params }) {
    const { record_id, record_type, target, contact_id, owner_account_id } =
      params.payload;
    const formData = new FormData();

    Array.from(this.inputTarget.files).forEach((file) => {
      formData.append("documents[]", file);
    });

    formData.append("record_id", record_id);
    formData.append("record_type", record_type);
    formData.append("target", target);
    formData.append("contact_id", contact_id);

    let endpoint;

    switch (record_type) {
      case "DcPensionDocument":
        endpoint = `/beta/accounts/${owner_account_id}/defined_contribution_pensions/${record_id}/dc_pension_documents`;
        break;
      default:
        console.error("Unknown record type. Operation aborted.");
        return;
    }

    await post(endpoint, {
      responseKind: "turbo-stream",
      body: formData,
    });
  }
}
