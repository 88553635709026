import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.body.classList.add("--no-scroll");
  }

  disconnect() {
    document.body.classList.remove("--no-scroll");
  }

  close(event) {
    event.preventDefault();
    this.element.remove();
  }
}
